import {
    Col, Container,
    Row, Spacer,
    Svg, Typography
} from "@proxiserve-ui-components";
import { BaseApp } from "@templates";
import React from "react";
import EmailSend from "../assets/images/email-send.svg";

const SuccessForm = () => {
    return (
        <BaseApp>
            <Spacer mb="176px"></Spacer>
            <Container>
                <Row justifyContent="center">
                    <Col sm={8}>
                        <Svg data={EmailSend} center></Svg>
                        <Spacer mb="45px"></Spacer>
                        <Typography balise="h1" align="center">
                            Merci ! Votre message a bien été envoyé.
                        </Typography>
                        <Typography balise="p" align="center">
                            Nos équipes Proxiserve reviendront vers vous dans un
                            délai maximum de 24h.
                        </Typography>
                    </Col>
                </Row>
            </Container>
        </BaseApp>
    );
};

export default SuccessForm;
